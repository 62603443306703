@import "_bootstrap";
@import "bourbon/bourbon";

/* ==========================================================================
   Variables
   ========================================================================== */

  $navbar-height: 69px;
  $navbar-margin: 10px;
  
  $navbar-sub-height: 69px;
  $navbar-sub-margin: 10px;
  
  $navbar-sticky-stop: $navbar-margin;
   
  $text-color-light: #e7e7e7;
  
  $header-text-color-dark: #4a4a4a;
  $text-color-dark: #333;
  
  
  $footer-social-height: 140px;
  $footer-height: 62px;
  
  $navbar-background-color: rgb(47, 47, 47);
  $navbar-background-color-opacity: rgba(47, 47, 47, 0.95);
  
  $block-light-background-color: #f2f2f2;
  
   
/* ==========================================================================
   Base Styles, Bootstrap Modifications & Fonts Import
   ========================================================================== */

@import url(https://fonts.googleapis.com/css?family=Audiowide|Titillium+Web:400,700,600);
@import url(https://fonts.googleapis.com/css?family=Lato:300,700);

.center {

	text-align: center;

}

.embolden {

    font-family: "Titillium Web", sans-serif;
    font-weight: 700;

}

.shift {
    margin-left: -0.20em;
}

/*
 * A better looking default horizontal rule
 */

 hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

hr.large {
	
	margin: 40px 0;
	
}

hr.small {

	margin-top: 10px 0;

}
.mt {
	margin-top: 40px;
}

.mb {
	margin-bottom: 40px;
}

.pt {
	padding-top: 40px;
}

.pb {
	padding-bottom: 40px;
}

/* ==========================================================================
   General styles
   ========================================================================== */

html {
    min-height: 100%;
    position: relative;
}

body {

    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 1.6em;

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;

    background-color: #fefefe;
	
	position: relative;
		
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
    color: $header-text-color-dark;
    font-family: 'Titillium Web', sans-serif;
}

.subheader {

    font-style: italic;
    color: #b2b2b2;

    font-size: 0.7em;

}

a.offset {

    display:block;
    position: relative;
    top: -68px;

}

a {

    color: #797979;

}

h1, h2, h3, h4, h5, h6 {
	& > a {
	
		color: inherit;
		
		&:hover, &:active, &:focus {
			color: inherit;
			text-decoration: inherit;
		}
		
	}
}



.jumptarget {

	position: relative;
	top: -$navbar-height - 7px;
	display: block;
	
}

.btn-link {

    color: #797979 !important;

}

.btn-round {
	
	border-radius: 20px;
	border: 2px solid $text-color-light;
	padding: 15px;
	color: $text-color-light !important;	
	font-size: 1.2em; 
	font-weight: bold; 
	
	line-height: 32px;
	min-height: 63px;
	
	.unity-logo {

		width: 30px;
		height: 30px;
		margin-right: 5px;
		background-image: url(/assets/img/srdebugger/unity-logo-white.png);
		
		vertical-align: middle;
		background-size: 30px;
		display: inline-block;
	}

	.github-logo {

		width: 30px;
		height: 30px;
		font-size: 30px;
		line-height: 25px;
		margin-right: 5px;

		vertical-align: middle;
		display: inline-block;
	}
	&:hover, &:active, &:focus {
	
		border: 2px solid $text-color-light;
		background-color: $text-color-light;
		
		text-decoration: none;
		
		color: #222C37 !important;
	
		.unity-logo {
		
			background-image: url(/assets/img/srdebugger/unity-logo.png);

		}
	
	}
	
}


.btn-round-dark {
	
	border-radius: 20px;
	border: 1px solid $header-text-color-dark;
	padding: 15px;
	color: $text-color-dark !important;	
	font-size: 1.3em; 
	font-weight: 500; 
	
	line-height: 32px;
	min-height: 63px;
	
	.unity-logo {
	
		background-image: url(/assets/img/srdebugger/unity-logo.png);

		width: 30px;
		height: 30px;
		margin-right: 5px;
		
		vertical-align: middle;
		background-size: 30px;
		display: inline-block;
		
	}
		
	&:hover, &:active, &:focus {
	
		border: 1px solid $text-color-dark;
		background-color: $text-color-light;
		
		text-decoration: none;
		
		color: #222C37 !important;
	
	}
	
}

.post-list {

    list-style: none;
    padding: 0 0 0 0px;

}

.big-lead-text {

    font-size: 36px;
    font-weight: 500;
    color: $header-text-color-dark;

}

iframe, object, embed{max-width: 100%;}

.spotify-thumbnail iframe {

    height: 440px;

}

.spotify-thumbnail .caption h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 992px) {

    .spotify-thumbnail iframe {

        height: 80px;

    }

    .spotify-thumbnail .caption {
        text-align: left;
    }


}

.thumbnail-button {

	.thumbnail {
		border:0;
		/*box-shadow: 0 0 2px 2px #ddd;*/
	}
	
	.caption {

		border: 1px solid #b2b2b2;
		border-top: 0;
		border-radius: 0 0 5px 5px;

	}
	
	.caption h1, .caption h2, .caption h3, .caption h4, .caption h5, .caption h6 {
		
		margin-top: 10px;
		
	}

	&:hover {
	
		text-decoration: none;
		
		.thumbnail {
		    /* background-color: #f1f1f1;*/
			box-shadow: 0 0 2px #2a6496;
		}
		
	}
	
	&:active {
	
		.thumbnail {

			/* background-color: #f1f1f1;*/
			box-shadow: 0 0 6px #2a6496;

		}

	}	
	
}

.badge-new {
	vertical-align: middle;
	margin-top: -0.5em;
}

code {

	padding: 0;
	font-size: 14px;
	color: inherit;
	white-space: pre-wrap;
	background-color: #f5f5f5;
	border-radius: 0;
	
}

.section-light {
	background-color: $block-light-background-color; 
}

.docs {
	
	margin-top: -20px;
	
	h1 {
		margin: 40px 0 20px;
		padding-bottom: 8px;
		border-bottom: 1px solid #ccc;
	}
	
}

.dropshadow {
	box-shadow: 0px 3px 9px $header-text-color-dark;
}

.testimonial {

	background-color: $block-light-background-color;

	h1,h2,h3,h4,h5 {

		margin-top: 5px;

	}

}

/* ==========================================================================
   Nav-bar restyling
   ========================================================================== */

.navbar-offset {
    margin-top: $navbar-height + $navbar-margin;
}

.subnavbar-offset {
	margin: 0;
	padding-top: $navbar-height + $navbar-margin + $navbar-sub-margin;
}

.navbar-main {

    background-color: $navbar-background-color;
    background-color: $navbar-background-color-opacity;

	border-color: transparent;
	border: 0;
    font-weight: 600;

    padding-left: 10px;
    padding-right: 10px;

	width: 100%;
	
	&.affix-top {
	
		position: fixed; 
		top: 0;
		left: 0;
		z-index: 1000;
		
	}
	
	&.affix {
	
		position: absolute;
		top: $navbar-sticky-stop;
		left: 0;
		z-index: 1000;
		
	}
	
	.navbar-toggle {
	
		margin-top: 17px;
		margin-bottom: 17px;
		
	}
	
	.navbar-nav > li > a {

		line-height: 36px;
		color: #666;
		
		&:hover, &:focus {
		
			color: $text-color-light;
			background-color: #2f2f2f;
			
		}
	
	}
	
	.navbar-nav > .active > a {
	
		&, &:hover, &:focus {
		
			color: $text-color-light;
			background-color: transparent;
			border-bottom: 2px solid $text-color-light;
			
		}
	
	}
	
}

.navbar-main .container {

	@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
		width: 100%;
	}
	
}

.navbar-main .dropdown-menu {
    width: 100%;
    min-width: 100%;
    border: 0;

    /* alpha blend menu bg and button focus bg */
    background-color: rgba(22,22,22, 0.90);
    background-color: #2f2f2f;

    border-radius: 0 0 2px 2px;

}

.navbar-main .dropdown.open .dropdown-toggle {
    background-color: #2f2f2f !important;
    color: $text-color-light !important;
}

.navbar-main .dropdown-menu li a {
    padding: 3px 10px;
    font-size: 1.1em;
}

.navbar-main .navbar-nav .open .dropdown-menu > li > a,
.navbar-main .navbar-nav .open .dropdown-menu > li > a {
    color: #666;
    background-color: transparent;
}

.navbar-main .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-main .navbar-nav .open .dropdown-menu > li > a:focus {
    color: $text-color-light;
    background-color: transparent;
}

@media (max-width: 767px) {

    .navbar-main .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-main .navbar-nav .open .dropdown-menu > li > a:focus {
        color: $text-color-light;
        background-color: transparent;
    }

    .navbar-main .navbar-collapse {
        border-top: 0;
    }

    .navbar-main .navbar-nav > .active > a, .navbar-main .navbar-nav > .active > a:hover, .navbar-main .navbar-nav > .active > a:focus {
        border-bottom: 0;
    }

}


.navbar-sub {

	height: $navbar-sub-height;
	padding-top: 0px;
	width: 100%;
	
	background-color: rgba(47, 47, 47, 0);
	@include transition(background-color 0.25s);
	
	.container {
	
		border-bottom: 1px solid $text-color-light;
		@include transition(border-bottom 0.25s);

	}
	
	.navbar-header {
		
		h1 {
			color: $text-color-light;
			font-family: 'Lato', sans-serif;
			margin: 0;
			
			line-height: $navbar-sub-height - 7px;
		}
		
	}
	
	.navbar-nav > li > a {

		line-height: 36px;
		color: #666;
		font-weight: 600;
		margin-top: 0px;
		
		&:hover, &:focus {
		
			color: $text-color-light;
			background: none;
			
		}
	
	}
	
	.navbar-nav > .active > a {
	
		@include transition(border-bottom 0.2s);
	
		color: $text-color-light;
		
		&:hover, &:focus {
		
			color: $text-color-light;
			background-color: transparent;
			
		}
		
		border-bottom: 2px solid transparent;
	
	}
	
	&.affix {

		position: fixed;
		top: 0;
		left: 0;
		
		background-color: $navbar-background-color;
		background-color: $navbar-background-color-opacity;
		
		z-index: 1000;
		
		.container {
			border-bottom: 1px solid transparent;
		}
		
		.navbar-nav > .active > a {
		
			background-color: transparent;
			border-bottom: 2px solid $text-color-light;
			
		}
		
	}
	
}

.navbar-sub-light {

	@include transition(background-color 0.25s);
	
	.container {
	
		border-bottom: 1px solid #ccc;
		@include transition(border-bottom 0.25s);

	}
	
	.navbar-header {
		
		h1 {
			@include transition(color 0.25s);
			color: $header-text-color-dark;
		}
		
	}
	
	.navbar-nav > li > a {

		color: #666;
		
		&:hover, &:focus {
		
			color: $text-color-dark;
			background: none;
			
		}
	
	}
	
	.navbar-nav > .active > a {
		
		font-weight: bold;

		&, &:hover, &:focus {
			color: $text-color-dark;			
		}
	
	}
	
	&.affix {
			
		.navbar-header {
			h1 {
				color: $text-color-light;
			}
		}
		
			
		.navbar-nav > .active > a {
			
			font-weight: 600;
	
			&, &:hover, &:focus {
				color: $text-color-light;			
			}
		
		}
		
	}
	
}

.navbar-sub-container {
	height: $navbar-sub-height;
}

.navbar-brand {
	padding: 10px;
}

/* ==========================================================================
   Page Sidebar
   ========================================================================== */

/* sidebar */
.bs-docs-sidebar {

    padding-left: 20px;
    margin-top: 25px;
    margin-bottom: 10px;
	max-width: 292px;

	&.affix {
	
		position: fixed;	
		top: $navbar-height + $navbar-margin;
		
	}
	
	.nav {
		
		/* all links */
		li {
		
			text-overflow: ellipsis;
			overflow: hidden;
			width: 100%;
		
			a {
			
			    color: #999;
				border-left: 2px solid transparent;
				padding: 4px 20px;
				font-weight: 400;
				
			}
			
		}
		
		/* nested links */
		.nav>li>a {
			padding-top: 1px;
			padding-bottom: 1px;
			padding-left: 30px;
			font-size: 0.9em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		
		/* active & hover links */
		&>.active>a, &>li>a:hover, &>li>a:focus {
			color: $text-color-dark;                 
			text-decoration: none;          
			background-color: transparent;  
			border-left-color: $text-color-dark; 
		}
		
		/* all active links */
		&>.active>a, &>.active:hover>a, &>.active:focus>a {
			font-weight: 700;
		}
		
		/* nested active links */
		& .nav>.active>a, & .nav>.active:hover>a, & .nav>.active:focus>a {
			font-weight: 500;
		}
		
		/* hide inactive nested list */
		ul.nav {
			display: none;           
		}
		/* show active nested list */
		&>.active>ul.nav {
			display: block;           
		}
		
	}
	
}

/* Remove padding on smaller screens */
@media (min-width: 1px) and (max-width: $screen-sm-max) {
	.bs-docs-sidebar {
		padding-left: 0px;
	}
}   

/* ==========================================================================
   Big Ass Header
   ========================================================================== */

.bigassheader {

	position: relative;

    text-align: center;

    padding-top: 200px;
    width: 100%;
    min-height: 500px;
	
	&, .bigassimage {
	
		background-repeat: no-repeat;

		-webkit-background-size: 100%;
		-moz-background-size: 100%;
		-o-background-size: 100%;
		background-size: 100%;

		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-position: center 40%;

	}
	
	.bigassimage {
	
		position: absolute;
	
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 0;
		
		&.fade {
			z-index: 0;
			transition: opacity 0.1s linear 0.4s;
		}
		
		&.fade.in {
			z-index: 1;
			transition: opacity 0.4s linear 0s;
		}
		
	}
	
	.bigasscontent {
	
		position: relative;
		z-index: 2;
	
	}
	
}

@media (max-width: 767px) {

    .bigassheader.responsive {

        padding-top: 100px;
        min-height: 220px;

        background-size: 150%;

    }

}

/*.bigassheader h1 {

    color:$text-color-light;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 3em;
    font-weight: bold;

    text-shadow: #666 0px 0px 3px;

}

.bigassheader h4, .bigassheader h3, .bigassheader h2 {

    font-weight: 600;
    color:$text-color-light;

}*/

/* ==========================================================================
   Blog Posts
   ========================================================================== */

.post-header {

}

.post-meta {

    font-style: italic;
    color: #b2b2b2;

}

.related-post-list {

    list-style-type: none;
    padding: 0 0 0 5px;

}

/* ==========================================================================
   Carousel Tiles
   ========================================================================== */

.carousel-tile {

    position: relative;
    margin-bottom: 10px;

}

.carousel-tile img {
    width: 100%;
}

.carousel-tile .caption {


    width: 100%;

    position: absolute;
    bottom: 0px;

    overflow: hidden;

    background-color: rgba(22,22,22, 0.6);


    text-align: center;
    opacity: 0;

    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;


}

.carousel-tile .caption h3 {


    margin: 0;
    font-family: "Titillium Web", sans-serif;
    font-size: 2em;
    color: $text-color-light;

    padding-top: 20px;
    padding-bottom: 20px;

}

.carousel-tile:hover .caption, *:focus .carousel-tile .caption {

    opacity: 1;

}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    -webkit-transition: opacity .6s;
    -moz-transition: opacity .6s;
    -o-transition: opacity .6s;
    transition: opacity .6s;
}
.carousel-fade .carousel-inner .active {
    opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}
.carousel-fade .carousel-control {
    z-index: 2;
}



/* ==========================================================================
   Thumbnails
   ========================================================================== */

div.thumbnail {

    padding: 0;
    border-radius: 5px;
    overflow: hidden;
}

.img-responsive,
.thumbnail > video,
.thumbnail a > video,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}

/* ==========================================================================
   Wrap Sections
   ========================================================================== */

.footer-spacer {

	clear: both;
	height: $footer-social-height + $footer-height;
	
    width: 100%;
	
}

.footer-wrap {

	position: absolute; 
	bottom: 0;
	width: 100%;
	
}
   
.icon-footer {

    position: absolute;
    bottom: 60px;
    width: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: $block-light-background-color;

}

.icon-footer i {
	font-size: 40px;
	color: $header-text-color-dark
}

.icon-footer i:hover {
	color: #1abc9c
}

.footer {

    width: 100%;
	padding-top: 20px;
	padding-bottom: 10px;
	background-color: #2f2f2f;
}

.footer a {
    color: #f2f2f2;
}

.footer p {
	color: #f2f2f2;
	margin-left: 10px;
}

/* Screenshot grid */

.screenshot {

    width: 100%;
    display: inline-block;

    background-attachment: relative;
    background-position: center center;

    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.screenshot-large {

    height: 300px;

}
.screenshot-small {

    height: 150px;

}

.rounded-thumbnail {
	
	border-radius: 5px;
	border: 1px solid $text-color-light;
	
}

.play-button {
	
	width: 110px;
	height: 110px;
	border-radius: 55px;
	display: inline-block;
	background-color: rgba(255, 255, 255, 0.75);
	background-image: url(/assets/img/srdebugger/play-64.png);
	background-repeat: no-repeat;
	
	background-position: 60%;
	
	cursor: pointer;
	
}

.post-content img {

	max-width: 100%;

}

/* Offset the position the screen will scroll to when scrolling to an element */
h1[id]:before, h2[id]:before, h3[id]:before, h4[id]:before, h5[id]:before, h6[id]:before { 
	content: ''; display: block; position: relative; width: 0; height: $navbar-height + 10px; margin-top: -$navbar-height - 10px; 
}

.vertical-align {
  display: flex;
  flex-direction: row;
}

.vertical-align > [class^="col-"],
.vertical-align > [class*=" col-"] {
  display: flex;
  align-items: center;
  justify-content: center; /* Optional, to align inner items 
                              horizontally inside the column */
  text-align: justify;
}

.vertical-align h1,.vertical-align h2,
.vertical-align h3,
.vertical-align h4,
.vertical-align h5 {
	margin-top: 0;
}


/* ==========================================================================
   Feature List
   ========================================================================== */
   
/*.feature-list-row {

	display: table;

}

.feature-list-element {
	
	display: table-cell;
	vertical-align: middle;
	height: 160px;
	
}*/

div.feature-list-image {

	display: block;
	width: 100%;
	height: 140px;
	
	background-repeat: no-repeat;
    text-align:center;
	
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;


}

.feature-item {
	
	padding-left: 85px;
	margin-bottom: 40px;
	
	i {
		border-radius: 50%;
		border: 1px solid $header-text-color-dark;
		color: $header-text-color-dark;
		font-size: 24px;
		text-align: center;
		padding-top: 12px;
		position: absolute; top: 0; left: 0;
		display: inline-block;
		height: 50px;
		width: 50px;
		margin: 10px;
	}

	h1,h2,h3,h4,h5 {
		margin-top: 8px;
	}
	
	
}

.feature-item-small {

	padding-left: 45px;
	margin-bottom: 35px;
	position: relative;

	img {
		padding-top: 0px;
		position: absolute; top: 4px; left: 0;
		display: inline-block;
		width: 32px;
		margin: 0;
	}

	h1,h2,h3,h4,h5 {
		margin-top: 4px;
		margin-bottom: 5px;
	}


}

.header-tab-area {
	
	.tab-content {
	
		
	
	}
	
	.nav-container {
		
		width: 100%;
		background-color: $navbar-background-color;
		
	}
	
	.nav-tabs {
			
		border: 0;
			
		& > li {
		
			margin: 0;

			a, a:hover, a:focus, a:active {
				border: 0;
				margin: 0;
				border-radius: 0;
				text-align: center;
				color: $text-color-light;
				background-color: $navbar-background-color;
			}
						
		}
		
		& > li.active {

			a, a:hover, a:focus, a:active {
				color: $text-color-dark;
				background-color: $block-light-background-color;
			}
			
		}
		
	}
	
}